<template>
  <div style="  background: #151C33;">
    <Home/>
  </div>
</template>

<script>
import Home from "@/view/Home";

export default {
  name: "App",
  components:{
    Home,
  },
  data: () =>({

  }),
  mounted() {

  },

}
</script>

<style scoped>

</style>