import {tinABI} from "@/utils/tinAbi";
import {CONTRACT_ADDRESS} from "@/config/config";

const TronWeb = require('tronweb')

export const getTronWeb = () => {
    (window as any).tronWeb.setHeader({"TRON-PRO-API-KEY": 'fae00ec1-2503-4270-a7fa-b124b2c7c53b'});
    // console.log('defaultAddress',(window as any).tronWeb.defaultAddress)
    // tp钱包节点限制了请求速率， 所以这里使用官方节点进行数据请求
    const tronWeb = new TronWeb(
        {
    //         //测试网
    //         fullHost:'https://api.shasta.trongrid.io',
               fullHost: 'https://api.trongrid.io',
               headers: { "TRON-PRO-API-KEY": 'fae00ec1-2503-4270-a7fa-b124b2c7c53b' }
        }
    )
    // console.log('tronWeb',tronWeb)
    tronWeb.defaultAddress = (window as any).tronWeb.defaultAddress
    return tronWeb
    //测试网用
    //
    // return (window as any).tronWeb || (window as any).tronLink.tronWeb

}