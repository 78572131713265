
import {defineComponent} from 'vue'
import BigNumber from "bignumber.js";
import {tinABI} from "@/utils/tinAbi";
import {getTronWeb} from "@/api/getTribWeb";
import {eX, getLeftTime} from "@/utils/helpers";
import {CONTRACT_ADDRESS} from "@/config/config";
import RuleDialog from "@/components/RuleDialog.vue";
import Clipboard from 'clipboard'
// import Vconsole from 'vconsole'
// import { useI18n } from 'vue-i18n'
// import {useStore} from "@/store";
// import {AppActionTypes} from "@/store/modules/app/action-types";

export default defineComponent({
  name: "Home",
  components:{
    RuleDialog
  },
  data (){
    return {
      iconImg:require('../asset/tron-icon.png'),
      copyIcon: require('../asset/copy_icon.png'),
      supplyAmount:'',
      tabIndex:0,
      isConnect:false,
      maxAmount:new BigNumber(0),
      minAmount:new BigNumber(0),
      leftTime:0|| {},
      currentLeftTime: 0,
      myDepositAmount:new BigNumber(0),
      marketTotalDepositAmount:new BigNumber(0),
      myTRXBalance:new BigNumber(0),
      showContractDialog:false,
      accumulatedDeposit:new BigNumber(0),
      cumulativeWithdrawal:new BigNumber(0),
      totalUser:new BigNumber(0),
      inputTip:'立即存入',
      awaitForUpdate: true,
      userWithdrawInfo:[] as any,
      userDepositInfo:[] as any,
      showConnectWallet:true,
      ruleDialogVisible:false,
      jugTime:new BigNumber(0),
      linkAddress: '',
      promoteDialogVisible:false,
      userRefAddress:'',
      inputRefAddress:'',
      showRefAddressTip:false,
      refTip:'',
      userRefIsOk:false,
      expandBtn: false,
    }
  },
  setup(){
  },
  mounted() {
    this.connectWallet().then(
        ()=>{

        }
    )
    setTimeout(()=> {

      this.updateData().then(()=>{
        this.awaitForUpdate = false
      })
      setInterval(()=>{
        this.updateData().then(()=>{
        })
      },60000)
    },1000)
    setTimeout(() => {
      this.formatterLeftTime()
    },3000)
    this.linkAddress = window.location.href
  },
  computed:{
    depositBtnEnable : function (){
      if (Math.floor(Number(this.supplyAmount))!==Number(this.supplyAmount)){

        return false
      }
      if (new BigNumber(this.supplyAmount).isGreaterThan(this.maxAmount)) {

        return false
      }
      if (new BigNumber(this.supplyAmount).isLessThan(this.minAmount)) {

        return false
      }
      if (this.jugTime.isGreaterThan(0))
      {
        return false
      }
      if (new BigNumber(this.supplyAmount).isGreaterThan(this.myTRXBalance)){
        return false
      }
      return true

    }
  },
  methods: {
    async handleInputRefAddress () {
      if (this.inputRefAddress.length !==34) {
        this.showRefAddressTip = true
        this.refTip = '输入的推荐地址无效，请重新输入'
        this.inputRefAddress = ''
        return
      }
      const tronWeb = getTronWeb()
      const contract = await tronWeb.contract(tinABI,CONTRACT_ADDRESS);
      const isOK = await contract.checkRefValid(this.inputRefAddress).call()
      if (isOK) {
        this.promoteDialogVisible = false
        //交易时切换节点回默认节点 以防出现问题
        const tronWebSend = (window as any).tronWeb || (window as any).tronLink.tronWeb
        const contractSend = await tronWebSend.contract(tinABI,CONTRACT_ADDRESS);
        const amount = eX(new BigNumber(this.supplyAmount).toString(),6)
        await contractSend.deposit(
            this.inputRefAddress
        ).send({
          feeLimit:100_000_000,
          callValue:amount,
          shouldPollResponse:true
        })
        setTimeout(() => {
          this.updateData()
        }, 3000)
      } else {
        this.showRefAddressTip = true
        this.refTip = '推荐地址无效，请重新输入'
        this.inputRefAddress = ''
        return
      }
    },
    supplyAmountInput (){
      this.expandBtn = false
      if (Math.floor(Number(this.supplyAmount))!==Number(this.supplyAmount)){
        this.inputTip = '请输入整数'
        return
      }
      if (new BigNumber(this.supplyAmount).isGreaterThan(this.maxAmount)) {
        this.inputTip = '最大值'+this.maxAmount.toString()
        return
      }
      if (new BigNumber(this.supplyAmount).isLessThan(this.minAmount)) {
        this.inputTip = '最小值'+this.minAmount.toString()
        return
      }
      if (this.jugTime.isGreaterThan(0))
      {
        this.expandBtn = true
        this.inputTip = '请'+this.jugTime.div(60).toFixed(0) +'分钟后再存'
        return
      }
      if (new BigNumber(this.supplyAmount).isGreaterThan(this.myTRXBalance)){
        this.inputTip = '余额不足'
        return
      }
      if (this.awaitForUpdate) {
        this.expandBtn = true
        this.inputTip = '正在加载数据'
        return
      }
      this.inputTip ='立即存入'
    },
    async connectWallet () {
      if ((window as any).tronWeb) {
        (window as any).tronWeb.ready;
        this.showConnectWallet = false
      }
    },
    async deposit () {
      if (Math.floor(Number(this.supplyAmount))!== Number(this.supplyAmount)){
        return
      }
      if (new BigNumber(this.supplyAmount).isGreaterThan(this.maxAmount)) {
        return
      }
      if (new BigNumber(this.supplyAmount).isLessThan(this.minAmount)) {
        return
      }
      const amount = eX(new BigNumber(this.supplyAmount).toString(),6)
      //交易时切换节点回默认节点 以防出现问题
      const tronWeb = (window as any).tronWeb || (window as any).tronLink.tronWeb
      const contract = await tronWeb.contract(tinABI,CONTRACT_ADDRESS);
      const leftTimes = await contract.leftTime().call()
      if (new BigNumber(leftTimes._hex).isGreaterThan(0)) {
        return
      }
      if (!this.userRefIsOk) {
        this.promoteDialogVisible = true
        return
      }
      await contract.deposit(
          CONTRACT_ADDRESS
      ).send({
        feeLimit:100_000_000,
        callValue:amount,
        shouldPollResponse:true
      })
      setTimeout(() => {
        this.updateData()
        this.getUserWithdrawInfos()
      }, 3000)
    },
    async updateData () {
      const tronWeb = getTronWeb()
      const contract = await tronWeb.contract(tinABI,CONTRACT_ADDRESS);
      const request1 = await Promise.all([
        contract._totalADepositAmount(tronWeb.defaultAddress.base58).call(), // 当前 = 累计 - 取款
        contract._totalDepositAmount(tronWeb.defaultAddress.base58).call(), // 累计
        contract._totalWithdrawAmount(tronWeb.defaultAddress.base58).call(),
      ])

      const myDeposit = request1[0]
      const accumulateDeposit= request1[1]
      const totalWithdraw = request1[2]


      const request2 = await Promise.all([
        contract.contractBalance().call(),
        contract.myBalance().call(),
        contract.userCount().call(),
        contract.refs(tronWeb.defaultAddress.base58).call(),
      ])

      const contractBalance = request2[0]
      const myBalance =  request2[1]
      const userCount = request2[2]
      this.userRefAddress = request2[3]

      const request3 = await Promise.all([
        contract.maxAmount().call(),
        contract.minAmount().call(),
        contract.checkRefValid(this.userRefAddress).call(),
        contract.leftTime().call()
      ])
      const max =request3[0]
      const min =request3[1]
      this.userRefIsOk = request3[2]
      const jug = request3[3]
      this.jugTime = new BigNumber(jug._hex)




      this.minAmount = new BigNumber(min._hex)
      this.maxAmount = new BigNumber(max._hex)


      this.myDepositAmount = eX(new BigNumber(myDeposit._hex).toString(),-6)

      this.accumulatedDeposit = eX(new BigNumber(accumulateDeposit._hex).toString(),-6)
      this.cumulativeWithdrawal = eX(new BigNumber(totalWithdraw._hex).toString(),-6)

      // console.log('contractBalance',contractBalance)
      this.marketTotalDepositAmount = eX(new BigNumber(contractBalance._hex).toString(),-6)

      this.myTRXBalance = eX(new BigNumber(myBalance._hex).toString(),-6)

      this.totalUser = new BigNumber(userCount._hex)
      //
      // const depositHistory =await contract.getWithDrawDeposit(tronWeb.defaultAddress.base58).call()
      // console.log('depositHistory',depositHistory)
      //accumulatedDeposit
      //myDepositAmount
    },
    async formatterLeftTime () {
      const tronWeb = getTronWeb()
      const contract = await tronWeb.contract(tinABI,CONTRACT_ADDRESS);
      const request = await Promise.all([
        contract.getWithDrawDeposit(tronWeb.defaultAddress.base58).call(),
        this.getUserWithdrawInfos()
      ])

      const withDrawDeposit = request[0]
      this.currentLeftTime  = Number(new BigNumber(withDrawDeposit.time._hex).times(1000))
      if (this.currentLeftTime === 0)  {
        setInterval(() => {
          const formatter = getLeftTime( 0)
          this.leftTime = formatter
        },1000)
      }else {
        setInterval(() => {
          const currentTime =new Date()
          const formatter = getLeftTime( Number(currentTime)-this.currentLeftTime)
          this.leftTime = formatter
        },1000)
      }


      // this.leftTime = new Date(Number(new BigNumber(currentLeftTime._hex).toString())*1000).toLocaleString()
    },
    async getUserWithdrawInfos () {
      const tronWeb = getTronWeb()
      const contract = await tronWeb.contract(tinABI,CONTRACT_ADDRESS);
      const withdrawRequest = await Promise.all([
        contract.getDepositDate(tronWeb.defaultAddress.base58).call(),
        contract.getDepositAmounts(tronWeb.defaultAddress.base58).call(),
        contract.getWithdrawBackAmounts(tronWeb.defaultAddress.base58).call(),
        contract.getWithdrawDate(tronWeb.defaultAddress.base58).call()
      ])
      const depositDates = withdrawRequest[0]
      const depositDetails = withdrawRequest[1]
      const withdrawBackAmounts = withdrawRequest[2]
      const withdrawDates = withdrawRequest[3]
      let withdrawList: any[] = []
      let depositList: any[] = []
      depositDates.forEach((date:any, index: number)=>{
        if (new BigNumber(depositDetails[index]._hex).isGreaterThan(0)){
          depositList.push(
              {
                //@ts-ignore
                amount: eX(new BigNumber(depositDetails[index]._hex).toString(),-6),
                //@ts-ignore
                time: new Date((Number(date._hex)*1000)).toLocaleString()
              }
          )
        }
      })
      withdrawDates.forEach((date:any, index:number)=>{
        if (new BigNumber(withdrawBackAmounts[index]._hex).isGreaterThan(0)){
          withdrawList.push(
              {
                //@ts-ignore
                amount: eX(new BigNumber(withdrawBackAmounts[index]._hex).toString(),-6),
                //@ts-ignore
                time: new Date((Number(date._hex)*1000)).toLocaleString()
              }
          )
        }

      })
      if (depositList.length>15) {
        const dLength = depositList.length
        depositList = depositList.splice(dLength - 15 , depositList.length)
      }
      if (withdrawList.length>15) {
        const wLength = withdrawList.length
        withdrawList = withdrawList.splice(wLength - 15 , withdrawList.length)
      }
      this.userWithdrawInfo  = withdrawList.reverse()
      this.userDepositInfo = depositList
    },
    showDialog () {
      this.ruleDialogVisible = true
    },
    handleCopy () {
      const clipboard = new Clipboard('.copyAddress')
      clipboard.on('success', () => {
        alert('复制成功')
        setTimeout(() => {
        }, 1000)
        clipboard.destroy()
      })
    }
  }
})
