import { createStore , createLogger} from 'vuex'
import { store as app, AppStore, AppState } from '@/store/modules/app'

export default createStore({
    state: {
        appTab: 'Home',
        clientViewPort:0,
    },
    getters: {
    },
    mutations: {
        updateAppTab(state:any,value:string){
            state.appTab = value
        },
        updateClientViewPort(state:any,value:number){
            state.clientViewPort = value
        },
    },
    actions: {
    },
    modules: {

    }
})

export interface RootState {
    app: AppState
}
export type Store = AppStore<Pick<RootState, 'app'>>

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []
export const store = createStore({
    plugins,
    modules: {
        app
    }
})

export function useStore (): Store {
    return store as Store
}