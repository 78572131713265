<template>
  <div class="dialog">
    <div style="display: flex;align-items: center;justify-content: space-between">
      <span class="size1">正常存款合约执行规则</span>
      <q-btn style="transform: translate(15px, -13px);" icon="close" flat round v-close-popup />
    </div>

    <p class="size1">存入合约中最早的一笔存款订单，同时满足以下四个条件即为正常存款，合约将自动执行取回这笔存款存入额{{backRatio.toString()}}%的资产</p>
    <p>1、立即存入≥被执行订单存入金额。</p>
    <p>2、被执行订单存入时间≥<span style="font-size: 16px">{{intervalTimes.toFixed(0,0)}}</span>天≤<span style="font-size: 16px">{{expiredTime.toFixed(0,0)}}</span>天。</p>
    <p>3、本账户静态累计存款收益≤{{withdrawRate.toString()}}%。</p>
    <p>4、合约余额满足本次执行取款金额。</p>
    <p class="size1">非正常存款合约执行规则：</p>
    <p>1、时间不足<span style="font-size: 16px">{{intervalTimes.toFixed(0,0)}}</span>天合约只执行存入。</p>
    <p>2、存款超<span style="font-size: 16px">{{expiredTime.toFixed(0,0)}}</span>天只取出存款额{{expriedRate.toString()}}%。</p>
    <p>3、超出收益执行取出存款额{{expriedRate.toString()}}%。</p>
    <p>4、合约余额不足取出存款额{{loveReturnRate.toString()}}%。</p>
    <p class="size1">推广奖励：</p>
    <p>直推奖：首次存款额{{ refFeeRate1.toString() }}%，小推大烧伤。</p>
    <p>间推奖：首次存款额{{refFeeRate2.toString()}}%，小推大烧伤。</p>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {getTronWeb} from "@/api/getTribWeb";
import {tinABI} from "@/utils/tinAbi";
import {CONTRACT_ADDRESS} from "@/config/config";
import BigNumber from "bignumber.js";

export default defineComponent({
  name: "RuleDialog",
  data(){
    return {
      backRatio:0,
      expiredTime:new BigNumber(0),
      intervalTimes:new BigNumber(0),
      expriedRate:0,
      loveReturnRate:0,
      refFeeRate1:0,
      refFeeRate2:0,
      withdrawRate:0,
    }
  },
  mounted() {
    this.updateRulerData()
  },
  methods:{
    async updateRulerData() {
      const tronWeb = getTronWeb()
      const contract = await tronWeb.contract(tinABI,CONTRACT_ADDRESS);
      const request = await Promise.all([
          contract.BACK_RATE().call(),
          contract.EXPIRED_TIME().call(),
          contract.INTERVAL_TIMES().call(),
          contract.expiredRate().call(),
          contract.loveReturnRate().call(),
          contract.refFeeRate1().call(),
          contract.refFeeRate2().call(),
          contract.withdrawRate().call()
      ])
      this.backRatio = new BigNumber(request[0]._hex)
      this.expiredTime = new BigNumber(request[1]._hex).div(86400)
      this.intervalTimes = new BigNumber(request[2]._hex).div(86400)
      this.expriedRate = new BigNumber(request[3]._hex)
      this.loveReturnRate = new BigNumber(request[4]._hex)
      this.refFeeRate1 = new BigNumber(request[5]._hex)
      this.refFeeRate2 = new BigNumber(request[6]._hex)
      this.withdrawRate = new BigNumber(request[7]._hex).plus(100)

      console.log('request',request)
    }
  }
})
</script>

<style scoped lang="scss">
.dialog {
  background: white;
  padding: 15px;
  .size1{
    font-size: 16px;
    font-weight: bold;
  }
}::v-deep .material-icons {
   font-family: 'Material Icons' !important;
 }

</style>